/** @format */
import "./style.scss";
import { knowList } from "@/contants";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";

import logo from "@/assets/fill_logo_white1.png";

import Header from "./Header";
import FirstScreen from "./FirstScreen";
import Holders from "./Holders";
import Video from "./Video";
import Services from "./Services";
import Team from "./Team";
import Partners from "./Partners";
import Blog from "./Blog";
import Footer from "./Footer";
import SubscribeModal from "./SubscribeModal";
import { useMemo, useState, useEffect } from "react";
import { getLanguage } from "@/utils";

const twoColors = { "0%": "#4ADFE7", "100%": "#4094E0" };

export default () => {
  const { t, i18n } = useTranslation();
  const [_knowList, setKnowList] = useState(knowList);
  useEffect(() => {
    setKnowList(
      knowList.map((item) => {
        const _item = item.item.map((item1) => {
          return {
            ...item1,
            url: item1.url.replaceAll(
              "/lang/",
              `/${getLanguage(i18n.language)}/`
            ),
          };
        });
        return { ...item, item: _item };
      })
    );
    const handleLanguageChange = () => {
      setKnowList(
        knowList.map((item) => {
          const _item = item.item.map((item1) => {
            return {
              ...item1,
              url: item1.url.replaceAll(
                "/lang/",
                `/${getLanguage(i18n.language)}/`
              ),
            };
          });
          return { ...item, item: _item };
        })
      );
    };

    // 监听语言变化事件
    i18n.on("languageChanged", handleLanguageChange);

    // 清理函数，在组件卸载时移除监听器
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  return (
    <div className="fill">
      <Header />

      <div className="fill-main">
        <FirstScreen />

        <Holders />

        <Video />

        <Services />

        <Team />

        <Blog />

        <Partners />

        <div className="know-card" data-aos="fade-up" id="docs">
          <div className="fill-card">
            {_knowList.map((item) => (
              <div className="know-container" key={item.title}>
                <div className="title">{t(`know.${item.title}`)}</div>
                {/* <Progress
                  percent={10}
                  showInfo={false}
                  strokeColor={twoColors}
                  size={["100%", 2]}
                /> */}
                <div className="cut-progress"></div>
                {item.item.map((item1) => (
                  <div
                    key={item1.tk}
                    className="desc"
                    onClick={() => {
                      if (item1.url) {
                        window.open(item1.url);
                      }
                    }}
                  >
                    {t(`know.${item1.tk}`)}
                  </div>
                ))}
              </div>
            ))}
            <div className="fill-logo logo">
              <img src={logo} width={190} className="logo" alt="" />
              <SubscribeModal />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
